/* LandingForm.css */

/* Form section */
.landing-form-section {
    padding: 2rem;
    background-color: #f9f9f9;
  }
  
  .landing-form-title {
    font-size: 2rem;
    text-align: center;
    margin-top: 1rem;
    margin-bottom: 2rem;
    color: #333;
  }
  
  .landing-form-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center; /* Asegura el centrado vertical */
    gap: 2rem;
  }
  
  @media (min-width: 768px) {
    .landing-form-content {
      flex-direction: row;
      justify-content: center; /* Centra horizontalmente */
      align-items: center; /* Centra verticalmente todos los hijos */
    }
  
    .landing-form-image {
      display: block; /* Se muestra en pantallas grandes */
      justify-content: center; /* Centrado horizontal */
      align-items: center; /* Centrado vertical */
    }
  
    .landing-form-image img {
      width: 90%; /* Ajusta el tamaño de la imagen */
      height: auto;
      border-radius: 8px;
    }
  }
  
  .landing-form {
    width: 100%;
    height: auto;
    max-width: 400px;
    background: white;
    padding: 2rem;    
    border-radius: 8px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  }
  
  .landing-form-group {
    display: flex;
    flex-direction: column;
    margin-bottom: 1rem;
  }
  
  .landing-form-group label {
    font-size: 1rem;
    margin-bottom: 0.5rem;
    color: #333; /* Asegura que las etiquetas tengan un color consistente */
  }
  
  .landing-form-group input,
  .landing-form-group textarea {
    padding: 0.5rem;
    font-size: 1rem;
    border: 1px solid #ccc;
    border-radius: 4px;
    color: #333; /* Color del texto en los inputs */
  }
  
  .landing-form-group textarea {
    resize: none;
  }
  
  .landing-form-submit-button {
    padding: 1rem;
    font-size: 1rem;
    background-color: #000;
    color: #fff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s;
    margin: 1rem auto 0 auto; /* Centra horizontalmente el botón */
    display: block; /* Asegura que el botón ocupe solo el espacio necesario */
  }
  
  .landing-form-submit-button:hover {
    background-color: #444;
  }
  
  /* Mobile adjustments */
  @media (max-width: 768px) {
    .landing-form-section {
      margin-top: -2rem;
    }
  
    .landing-form-title {
      font-size: 1.5rem;
      padding: 1.2rem 0rem;
    }
  
    .landing-form-content {
      display: flex;
      flex-direction: column;
      align-items: center; /* Asegura que todo esté centrado */
      justify-content: center;
    }
  
    .landing-form-image {
      display: flex;
      justify-content: center; /* Centra la imagen dentro del contenedor */
      width: 100%; /* Asegura que el contenedor no limite el centrado */
      margin-bottom: 2rem; /* Añade espacio entre la imagen y el formulario */
    }
  
    .landing-form-image img {
      width: 60%; /* Ajusta el tamaño de la imagen */
      max-width: 300px; /* Evita que crezca demasiado */
      height: auto;
    }
  
    .landing-form-submit-button {
      padding: 1rem 1.5rem;
    }
  }
  