/* src/components/HomeBannerMobile/HomeBannerMobile.css */

.home-banner-mobile-container {
  position: relative;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
}

.home-banner-mobile-image {
  width: 100vw;
  height: 100vh;
  object-fit: cover; /* Ensure the image covers the entire screen */
}

/* Overlay specific to HomeBannerMobile */
.home-banner-mobile-slide::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  pointer-events: none;
}

.home-banner-mobile-content {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  color: white;
  z-index: 1;
  width: 90%;
}

.home-banner-mobile-vision-label {
  font-size: 2rem;
  margin-bottom: 1rem;
}

.home-banner-mobile-vision-sublabel {
  font-size: 1.3rem;
  margin-bottom: 2rem;

}

.home-banner-mobile-action-container {
  display: flex;
  justify-content: center;  /* Center horizontally */
  align-items: center;      /* Center vertically */
  position: absolute;
  bottom: 10%;                 /* Position at 50% from the top */
  left: 50%;                /* Position at 50% from the left */
  transform: translate(-50%, -50%); /* Adjust to center exactly */
  margin-top: 2rem;         /* Maintain margin if needed */
  z-index: 1;               /* Ensure it stays above background elements */
  width: 100%;
}


