/* General styles */
body {
  font-family: Arial, sans-serif;
  background-color: #f9f9f9;
  margin: 0;
  padding: 0;
}

/* Login form styles */
.leads-page-login-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #f9f9f9;
}

.leads-page-login-form {
  background: white;
  padding: 30px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  width: 350px;
  text-align: center;
}

.leads-page-login-title {
  font-size: 24px;
  margin-bottom: 20px;
}

.leads-page-login-input {
  width: 100%;
  padding: 10px;
  margin-bottom: 15px;
  border: 1px solid #ddd;
  border-radius: 4px;
}

.leads-page-login-button {
  width: 100%;
  padding: 10px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.leads-page-login-button:hover {
  background-color: #0056b3;
}

.leads-page-error {
  color: red;
  font-size: 14px;
}

/* Table styles */
.leads-page-container {
  padding: 20px;
}

.leads-page-title {
  text-align: center;
  margin-bottom: 20px;
  margin-top: 4rem;
}

.leads-page-table {
  margin: 0 auto;
  width: 90%;
  border-collapse: collapse;
  background: white;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.leads-page-table th,
.leads-page-table td {
  border: 1px solid #ddd;
  padding: 10px;
  text-align: center;
}

.leads-page-table-header {
  background-color: #f4f4f9;
  font-weight: bold;
}

.leads-page-button {
  background-color: #28a745;
  color: white;
  border: none;
  padding: 5px 10px;
  border-radius: 4px;
  cursor: pointer;
}

.leads-page-button:hover {
  background-color: #218838;
}

/* Modal styles */
.leads-page-modal {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.leads-page-modal-content {
  background: white;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
  width: 400px;
  text-align: center;
}

/* Botón de "Ver más" estilo Google */
.leads-page-button-view-more {
  background-color: #4285f4; /* Azul de Google */
  color: white;
  border: none;
  padding: 5px 10px;
  border-radius: 4px;
  cursor: pointer;
  font-size: 14px;
}

.leads-page-button-view-more:hover {
  background-color: #357ae8; /* Azul más oscuro al hacer hover */
}
