.home-showcase-mobile-container {
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: white;
  overflow: hidden;
}

.home-showcase-mobile-lineas-wrapper {
  height: 10rem;  /* Ajuste para móviles */
  margin-top: 4rem;
}

.home-showcase-mobile-series-wrapper {
  margin-top: 4rem;
  flex-grow: 1;  /* Ocupa el espacio restante */
}

/* Remueve el contenido predeterminado de las flechas de slick para móvil */
.slick-prev:before, .slick-next:before {
  content: '' !important;
}
