/* General styles */
.landing-peru-page {
    font-family: Arial, sans-serif;
  }
  
  /* Hero section */
  .landing-peru-hero {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100vh;
    padding: 2rem;
    text-align: center;
    position: relative;
    overflow: hidden;
  }
  
  @media (min-width: 768px) {
    .landing-peru-hero {
      flex-direction: row;
      justify-content: flex-start;
      text-align: left;
      gap: 3rem;
      padding-left: 8rem;
    }
  }
  
  .landing-peru-text-section {
    max-width: 700px;
    z-index: 1;
  }
  
  .landing-peru-title {
    font-size: 2.8rem;
    font-weight: bold;
    margin-bottom: 1rem;
    line-height: 1.2;
    color: #333; /* Asegura un color consistente */
  }
  
  .landing-peru-subtitle {
    font-size: 1.4rem;
    color: #666;
    margin-bottom: 1.5rem;
  }
  
  .landing-peru-contact-button {
    padding: 1rem 2rem;
    margin-top: 2rem;
    font-size: 1.2rem;
    background-color: #000;
    color: #fff;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    transition: background-color 0.3s;
  }
  
  .landing-peru-contact-button:hover {
    background-color: #444;
  }
  
  .landing-peru-image-section {
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    width: 50%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    overflow: hidden;
  }
  
  .landing-peru-page-img {
    width: 80%;
    height: auto;
    position: absolute;
    animation: slide-in-out 4s ease-in-out infinite;
  }
  
  /* Form section */
  .landing-peru-form-section {
    padding: 2rem;
    background-color: #f9f9f9;
  }
  
  .landing-peru-form-title {
    font-size: 2rem;
    text-align: center;
    margin-top: 1rem;
    margin-bottom: 2rem;
    color: #333;
  }
  
  .landing-peru-form-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center; /* Asegura el centrado vertical */
    gap: 2rem;
  }
  
  @media (min-width: 768px) {
    .landing-peru-form-content {
      flex-direction: row;
      justify-content: center; /* Centra horizontalmente */
      align-items: center; /* Centra verticalmente todos los hijos */
    }
 
    .landing-peru-form-image {
      display: block; /* Se muestra en pantallas grandes */
      justify-content: center; /* Centrado horizontal */
      align-items: center; /* Centrado vertical */
    }
  
    .landing-peru-form-image img {
      width: 90%; /* Ajusta el tamaño de la imagen */
      height: auto;
      border-radius: 8px;
    }
  }
  
  .landing-peru-form {
    width: 100%;
    height: auto;
    max-width: 400px;
    background: white;
    padding: 2rem;    
    border-radius: 8px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    
  }
  
  .landing-peru-form-group {
    display: flex;
    flex-direction: column;
    margin-bottom: 1rem;
  }
  
  .landing-peru-form-group label {
    font-size: 1rem;
    margin-bottom: 0.5rem;
    color: #333; /* Asegura que las etiquetas tengan un color consistente */
  }
  
  .landing-peru-form-group input,
  .landing-peru-form-group textarea {
    padding: 0.5rem;
    font-size: 1rem;
    border: 1px solid #ccc;
    border-radius: 4px;
    color: #333; /* Color del texto en los inputs */
  }
  
  .landing-peru-form-group textarea {
    resize: none;
  }
  
  .landing-peru-form-submit-button {
    padding: 1rem;
    font-size: 1rem;
    background-color: #000;
    color: #fff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s;
    margin: 1rem auto 0 auto; /* Centra horizontalmente el botón */
    display: block; /* Asegura que el botón ocupe solo el espacio necesario */
  }
  
  .landing-peru-form-submit-button:hover {
    background-color: #444;
  }
  
  /* Animación de deslizamiento */
  @keyframes slide-in-out {
    0% {
      opacity: 0;
      transform: translateX(100%);
    }
    25% {
      opacity: 1;
      transform: translateX(5%);
    }
    75% {
      opacity: 1;
      transform: translateX(5%);
    }
    100% {
      opacity: 0;
      transform: translateX(-100%);
    }
  }

/* Mobile adjustments */
@media (max-width: 768px) {
  /* Contenedor principal */
  .landing-peru-hero {
    display: flex;
    flex-direction: column-reverse;
    align-items: center;
    text-align: center;
  }

  /* Título arriba */
  .landing-peru-title {
    font-size: 1.8em; /* Ligeramente más grande */
    margin-top: 1em;
    margin-bottom: 1em; /* Muchísimo más espacio debajo */
    padding: 2em 0em 3em;
    order: 1;
  }

  /* Imagen debajo del título */
  .landing-peru-image-section {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 2em auto;
    width: 100%;
    margin-top: 4em; /* Más espacio antes de la imagen */
    margin-bottom: 10em; /* Más espacio después de la imagen */
    order: 2;
  }

  .landing-peru-page-img {
    width: 80%; /* Reduce el tamaño de la imagen para más equilibrio */
    height: auto;
    position: static;
    animation: none;
  }

  /* Subtítulo debajo de la imagen */
  .landing-peru-subtitle {
    font-size: 1.3em; /* Texto más grande */
    padding: 1.5em 0em 1em;
    margin-top: 4em; /* Mucho más espacio arriba */
    margin-bottom: 0rem; /* Mucho más espacio debajo */
    color: #666;
    order: 3;
  }

  /* Botón debajo del subtítulo */
  .landing-peru-contact-button {
    order: 4;
    margin-top: 2rem; /* Más espacio antes del botón */
    margin-bottom: 4em; /* Mucho más espacio después del botón */
    padding: 0.8em 1.8em; /* Botón más grande */
    font-size: 1.3em; /* Texto del botón más grande */
  }

  .landing-peru-form-section{
    margin-top: -2rem;
  }

  .landing-peru-form-title{
    font-size: 1.5rem; /* Texto del botón más grande */
    padding: 1.2rem 0rem;

  }

  .landing-peru-form-content {
    display: flex;
    flex-direction: column;
    align-items: center; /* Asegura que todo esté centrado */
    justify-content: center;

  }

  .landing-peru-form-image {
    display: flex;
    justify-content: center; /* Centra la imagen dentro del contenedor */
    width: 100%; /* Asegura que el contenedor no limite el centrado */
    margin-bottom: 2rem; /* Añade espacio entre la imagen y el formulario */
  }

  .landing-peru-form-image img {
    width: 60%; /* Ajusta el tamaño de la imagen */
    max-width: 300px; /* Evita que crezca demasiado */
    height: auto;
  }

  .landing-peru-form-submit-button{
    padding: 1rem 1.5rem;
  }

}
