/* src/components/HomeBannerDesktop/HomeBannerDesktop.css */

.home-banner-desktop-container {
  position: relative;
  width: 100%;
  height: 100vh;
  overflow: hidden;
}

.home-banner-desktop-slide {
  position: relative;
  width: 100%;
  height: 100vh;
}

.home-banner-desktop-image {
  width: 100%;
  height: 100vh;
  object-fit: cover; /* Ensure the image covers the entire screen */
}

/* Add the dark overlay directly to the slide (div) */
.home-banner-desktop-slide::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1; /* Ensure the overlay is above the image */
  pointer-events: none; /* Ensure the overlay does not block interaction with other elements */
}

/* Content stays on top of the image and overlay */
.home-banner-desktop-content {
  position: absolute;
  bottom: 5%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  color: white;
  z-index: 2;
}

.home-banner-desktop-vision-label {
  font-size: 3rem;
  margin-bottom: 1rem;
}

.home-banner-desktop-vision-sublabel {
  font-size: 1.8rem;
  margin-bottom: 2rem;
  width: 60%;
  margin-left: auto;
  margin-right: auto;
}

.home-banner-desktop-action-container {
  margin-top: 2rem;
  display: flex;
  justify-content: center; /* Center the button horizontally */
}